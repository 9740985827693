import React from 'react'
import { ChakraProvider, useToast, Button, Wrap } from '@chakra-ui/react'

function Test() {
  const toast = useToast()
  const toastIdRef = React.useRef()

  const addToast = () => {
    toastIdRef.current = toast({
      description: 'This is an error message.',
      status: 'error', // Set the status to "error" to show an error toast
      duration: 5000,  // Duration in milliseconds
      isClosable: true,
    })
  }

  const close = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const closeAll = () => {
    toast.closeAll()
  }

  return (
    <ChakraProvider>
      <Wrap spacing={4} p={5}>
        <Button onClick={addToast}>Toast</Button>
        <Button onClick={close} variant="outline">Close Last Toast</Button>
        <Button onClick={closeAll} variant="outline">Close All Toasts</Button>
      </Wrap>
    </ChakraProvider>
  )
}

export default Test