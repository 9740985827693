import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { Tabs, SelectPicker, Button, Message } from "rsuite";
import "rsuite/SelectPicker/styles/index.css";
import IconCheckError from "../images/IconCheckError.svg";
// import { Uploader } from 'rsuite';
// import CameraRetroIcon from '@rsuite/icons/legacy/CameraRetro';

// import ProfileBunyod from "../images/Profile-Bunyod.jpg";
// import ProfileDoniyor from "../images/Profile-Doniyor.jpg";
import { ReactComponent as PasswordShow } from "../images/icon-eye.svg";
import { ReactComponent as PasswordHide } from "../images/icon-eye-hide.svg";
import IconImageThumb from "../images/icon-imge-thumb.svg";
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import PasswordChecklist from "react-password-checklist";
import { ReactComponent as IconValid } from "../images/IconValid.svg"; // Your custom check icon
import { ReactComponent as IconInvalid } from "../images/IconInvalid.svg"; // Your custom cross icon
import { left } from '@popperjs/core';
import { FaLessThanEqual } from 'react-icons/fa';

function UsersandRoles() {
    // SHOW HIDE PASSWORD
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const userid = localStorage.getItem("novauserid");
    const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
    const [isLoading, setLoading] = useState(true); // State to manage loading state
    const [searchInput, setSearchInput] = useState("");
    const [startPage, setStartPage] = useState(1);

    const [usersData, setUersData] = useState([]);
    const [usersCount, setUsersCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [modalData, setModalData] = useState(null);

    const [deleteName, setDeleteName] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    

    const [updateUserId, setUpdateUserId] = useState('');
    const [updateFirstname, setUpdateFirstname] = useState('');
    const [updateLastname, setUpdateLastname] = useState('');
    const [updateEmail, setUpdateEmail] = useState('');
    const [updatePassword, setUpdatePassword] = useState('');
    const [updateConfirmPassword, setUpdateConfirmPassword] = useState('');
    const [updateImage, setUpdateImage] = useState('');
    
    const [updateButtonLoader, setUpdateButtonLoader] = useState(false);


    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [updateImage1, setUpdateImage1] = useState('');
    const [image, setImage] = useState('');
    const [image1, setImage1] = useState('');
    const [addButtonLoader, setAddButtonLoader] = useState(false);

    const [roleData, setRoleData] = useState([]);
    const [role, setRole] = useState("");

    const [errorMessage, setErrorMessage] = useState('');
    const [messageType, setMessageType] = useState(null); // success, error, info

    const [isPasswordFilled, setIsPasswordFilled] = useState(false);
    const [validity, setValidity] = useState({
      minLength: false,
      capital: false,
      specialChar: false,
    });

    const [isPasswordFilled1, setIsPasswordFilled1] = useState(false);
    const [validity1, setValidity1] = useState({
      minLength: false,
      capital: false,
      specialChar: false,
    });

    const fetchGlobalReport = async () => {
        setLoading(true);
    
            try {
          const apiUrl = `${apiEndPoint}/get_all_users`;
          const requestData = {
            data: {
              user_id: userid,
              limit: "20",
              page: currentPage.toString(),
              q:searchInput,
            }
          };
          console.log(JSON.stringify(requestData));
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
          });
    
          const jsonData = await response.json();
          console.log(jsonData.data);
          const { status, message, total_pages, data } = jsonData.data;
          if (status == "success") {
            setLoading(false);
            setUsersCount(total_pages);
            setUersData(data);
            console.log(data);
          } else {
            console.error("Error: ", message);
          }
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      };

      useEffect(() => {
        fetchGlobalReport();
      }, [currentPage,searchInput]); // Regular effect for other dependencies
    
      const handlePageChange = (page) => {
        setCurrentPage(page);
        // Adjust start page dynamically
        if (page >= startPage + 5) {
          setStartPage(page - 4);
        } else if (page < startPage) {
          setStartPage(page);
        }
      };


      useEffect(() => {
        const fetchRolesList = async () => {
          const url = `${apiEndPoint}/get_roles_list`;
          const payload = {
            data: {
              user_id: userid // Replace with the actual user_id if dynamic
            }
          };
      
          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            });
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const responseData = await response.json();
            const data = responseData.data; // Flatten the response
    
            const formattedRolesData = data.data.map((roles) => ({
              label: roles.name,
              value: roles.identifier,
            }));
    
            setRoleData(formattedRolesData);
      
          } catch (error) {
            //setErrorMessage(error.message);
            //setMessageType('error');
            return false;
          }
        };
      
        fetchRolesList();
      }, []);


    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const [isActive, setIsActive] = useState(false);

const [inputValues, setInputValues] = useState({
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    field9: '',
    field10: ''
  });

  const handleInputChange = (e, fieldName) => {
    setInputValues({
      ...inputValues,
      [fieldName]: e.target.value
    });
  };

   // Calculate total number of pages
   const totalPages = Math.ceil(usersCount / 20); // Assuming 20 items per page
   const endPage = Math.min(startPage + 4, totalPages);


    // END SHOW HIDE PASSWORD
    const [activeKey, setActiveKey] = useState('Users');

    //UPLOAD PROFILE PIC
    
    const [isImageUploaded, setIsImageUploaded] = useState();
    const [selectedImage1, setSelectedImage1] = useState(null); // To store the actual image file

    //Add New Analyst UPLOAD
    const [isImageUploaded1, setIsImageUploaded1] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // To store the actual image file

    const readURL1 = (input) => {
        if (input && input[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              setSelectedImage(input[0]); // Stores the actual file for upload
              setIsImageUploaded1(true);
            }
            reader.readAsDataURL(input[0]);
        }
    }

    const handleImageChange1 = (e) => {
        readURL1(e.target.files);
    }

    // const handleImageChange1 = (event) => {
    //   const reader = new FileReader();
    //   const file = event.target.files[0];
    
    //   reader.onloadend = () => {
    //       setUpdateImage1(reader.result);
    //       setIsImageUploaded1(true);
    //   };
    
    //   if (file) {
    //       reader.readAsDataURL(file);
    //   }
    // };

const handleRemoveClick1 = () => {
  setImage(null); // Remove the image by setting it to null
  setIsImageUploaded1(false);
};


//END UPLOAD PROFILE PIC

// const data = ['Junior Analyst', 'Senior Analyst'].map(
//     item => ({ label: item, value: item })
// );

const handleUpdate = async () => {



  if(!updateFirstname){
    setErrorMessage('Please Enter First Name');
    setMessageType('error');
    return false;
  }
  else if(!updateLastname){
    setErrorMessage('Please Enter Last Name');
    setMessageType('error');
    return false;
  }
  else if(!role){
    setErrorMessage('Please Enter Role');
    setMessageType('error');
    return false;
  }
  const emailPattern1 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern1.test(updateEmail)) {
    setErrorMessage('Please Enter a Valid Email');
    setMessageType('error');
    return false;
  }
  // else if(!updateEmail){
  //   setErrorMessage('Please Enter Email');
  //   setMessageType('error');
  //   return false;
  // }

  if(updatePassword.length == 0 && updateConfirmPassword.length == 0)
  {

  }
  else {
    if (!updatePassword) {
      setErrorMessage('New Password is required.');
      setMessageType('error');
      return false;
    }
    if (!updateConfirmPassword) {
      setErrorMessage('Confirm Password is required.');
      setMessageType('error');
      return false;
    }
  
    if (updatePassword && updateConfirmPassword && updatePassword !== updateConfirmPassword) {
      setErrorMessage('New Password and Confirm Password do not match.');
      setMessageType('error');
      return false;
    }
    if (isPasswordFilled && Object.values(validity).every(v => v)) {
    }else {
      // Password is not valid, handle accordingly (e.g., show error message)
      //console.log('Password is not valid');
      setErrorMessage('Password does not meet the required criteria');
      setMessageType('error');
      return false;
    }
  }
  


  setUpdateButtonLoader(true);
  // let updatedBase64 = "";
  // if (updateImage) {
  //   updatedBase64 = updateImage.replace(/^data:image\/(png|jpeg);base64,/, "");
  // }

  const requestData = {
    data: {
        user_id: updateUserId,
        firstname: updateFirstname,
        lastname: updateLastname,
        authority_id: role,
        email: updateEmail,
        password: updatePassword,
        //profile_img:updatedBase64,
    }
};

console.log(JSON.stringify(requestData));


try {
    const response = await fetch(`${apiEndPoint}/save_user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
    });

    if (!response.ok) {
        throw new Error('There was an issue processing your request. Please try again');
    }

    const responseData = await response.json();
    if(responseData.data.status == "success")
    {

      if(selectedImage1) {
        const imageUploadSuccess = await uploadProfileImage(updateUserId, selectedImage1);
      }

      setErrorMessage('Data Updated Successfully');
      setMessageType('success');
      fetchGlobalReport();
      //return true;
      setTimeout(() => {
        const cancelButton = document.getElementById('Cancel_Update');
        if (cancelButton) {
          cancelButton.click();
        }
      }, 3000);
      setUpdateButtonLoader(false);
      
    }
    else{
      setErrorMessage(responseData.data.message);
      setMessageType('error');
    }
} catch (error) {
    console.error('Error:', error);
    setUpdateButtonLoader(false);
    return false;
} finally {
  setUpdateButtonLoader(false);
}


};


const handleAdd = async () => {



  if(!firstname){
    setErrorMessage('Please Enter First Name');
    setMessageType('error');
    return false;
  }
  else if(!lastname){
    setErrorMessage('Please Enter Last Name');
    setMessageType('error');
    return false;
  }
  else if(!role){
    setErrorMessage('Please Enter Role');
    setMessageType('error');
    return false;
  }
   // Email validation regex pattern
   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   if (!emailPattern.test(email)) {
     setErrorMessage('Please Enter a Valid Email');
     setMessageType('error');
     return false;
   }

  // else if(!email){
  //   setErrorMessage('Please Enter Email');
  //   setMessageType('error');
  //   return false;
  // }

 

    
    // if (!password) {
    //   setErrorMessage('New Password is required.');
    //   setMessageType('error');
    //   return false;
    // }
    // if (!confirmPassword) {
    //   setErrorMessage('Confirm Password is required.');
    //   setMessageType('error');
    //   return false;
    // }
  
    // if (password && confirmPassword && password !== confirmPassword) {
    //   setErrorMessage('New Password and Confirm Password do not match.');
    //   setMessageType('error');
    //   return false;
    // }
    // if (isPasswordFilled1 && Object.values(validity1).every(v => v)) {
    // }else {
    //   // Password is not valid, handle accordingly (e.g., show error message)
    //   //console.log('Password is not valid');
    //   setErrorMessage('Password does not meet the required criteria');
    //   setMessageType('error');
    //   return false;
    // }
  

  setAddButtonLoader(true);


  // let updatedBase64 = "";
  // if (updateImage) {
  //   updatedBase64 = updateImage.replace(/^data:image\/(png|jpeg);base64,/, "");
  // }

  const requestData = {
    data: {
        firstname: firstname,
        lastname: lastname,
        authority_id: role,
        email: email,
        //password: password,
        password: 'Nova@2024',
        //profile_img:updatedBase64,
    }
};

console.log(JSON.stringify(requestData));


try {
    const response = await fetch(`${apiEndPoint}/save_user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
    });

    if (!response.ok) {
        throw new Error('There was an issue processing your request. Please try again');
    }

    const responseData = await response.json();
    if(responseData.data.status == "success")
    {
      //responseData.data.user_id


       // Upload the profile image if it exists
       if (selectedImage) {
        const imageUploadSuccess = await uploadProfileImage(responseData.data.user_id, selectedImage);
        // if (imageUploadSuccess) {
        //   console.log('Profile image uploaded successfully.');
        // } else {
        //   setErrorMessage('Profile image upload failed.');
        //   setMessageType('error');
        // }
      }
      
      setErrorMessage('Data Added Successfully');
      setMessageType('success');
      ClearAll();
      fetchGlobalReport();
      //return true;
      setTimeout(() => {
        const cancelButton = document.getElementById('Cancel_Add');
        if (cancelButton) {
          cancelButton.click();
        }
      }, 3000);
      setAddButtonLoader(false);
      
    }
    else{
      setErrorMessage(responseData.data.message);
      setMessageType('error');
    }
} catch (error) {
    console.error('Error:', error);
    setAddButtonLoader(false);
    return false;
} finally {
  setAddButtonLoader(false);
}
};


const handleDelete = async () => {
  try {

    const response = await fetch(`${apiEndPoint}/deactivate_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          user_id: deleteId,
        },
      }),
    });


    if (!response.ok) {
      throw new Error('Failed to deactivate user');
    }

    const result = await response.json();
    if (result.status === 'success') {
      fetchGlobalReport();
      const cancelButton = document.getElementById('Delete_Update');
      if (cancelButton) {
        cancelButton.click();
      }
    } else {
      throw new Error('Unexpected response status');
    }
  } catch (error) {
    console.error('Error:', error);
    // Handle error response
  }
};


function ClearAll() {
  setFirstname('');
  setLastname('');
  setEmail('');
  setRole('');
  setIsImageUploaded1(false);
  setSelectedImage('');
  setImage('');
}




const uploadProfileImage = async (userId, profileImg) => {
  try {
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('profile_img', profileImg);


    const response = await fetch(`${apiEndPoint}/upload_profile_image`, {
      method: 'POST',
      body: formData,
    });

    console.log(response);

    if (!response.ok) {
      throw new Error('Image upload failed');
    }

    const responseData = await response.json();
    if(responseData.data.status == "success")
    {
      console.log('Image upload response:', responseData);
      return true;
    }
    else if(responseData.data.status == "error"){
      setErrorMessage(responseData.data.message);
      setMessageType('error');
      return false;
    }
    
  } catch (error) {
    console.log('CATCH : Image upload error:', error);
    return false;
  }
};




const handleItemClick = (item) => {
    setModalData(item); // Set the clicked item data in modalData
    
};

const handleImageChange = (event) => {
  const reader = new FileReader();
  const file = event.target.files[0];

  reader.onloadend = () => {
      setUpdateImage(reader.result);
      setIsImageUploaded(true);
      setSelectedImage1(file);
  };

  if (file) {
      reader.readAsDataURL(file);
  }
};

const handleRemoveClick = () => {
  setUpdateImage(null); // Remove the image by setting it to null
  setIsImageUploaded(false);
};

useEffect(() => {
  if (modalData) {
      setUpdateUserId(modalData.user_id);
      setUpdateFirstname(modalData.firstname);
      setUpdateLastname(modalData.lastname);
      setUpdateEmail(modalData.email);
      setUpdatePassword("");
      setUpdateConfirmPassword("");
      setUpdateImage(modalData.profile_img);
      setRole(modalData.authority_id);
  }
}, [modalData]);


const handleRoleChange = (value) => {
  setRole(value);
};


const hideMessage = () => {
  setMessageType(null);
};

const renderMessage = () => {
  if (messageType) {
    setTimeout(hideMessage, 4000);
  };

  switch (messageType) {
    case 'success':
      return (
        <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:"fixed", right:10, top:90, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_success'>
              <div className='icon_error_msg_inner Icon_success_inner'>
                <img src={IconCheckError} alt="Success Icon" />
                <div className="progress-circle progress_circle_success"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_success_text'>
              <h5>Success</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
    case 'error':
      return (
        <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:90, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_error'>
              <div className='icon_error_msg_inner Icon_error_inner'>
                <img src={IconError} alt="Error Icon" />
                <div className="progress-circle progress_circle_error"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_error_text'>
              <h5>Error</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      case 'info':
      return (
        <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:90, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_info'>
              <div className='icon_error_msg_inner Icon_info_inner'>
                <img src={IconInfo} alt="Info Icon" />
                <div className="progress-circle progress_circle_info"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_info_text'>
              <h5>Info</h5>
              <p>No reports found for the selected priority</p>
            </div>
          </div>
        </Message>
      );
      default:
        return null;
  }
};

const handlePasswordChange = (e) => {
  const newPassword = e.target.value;
  setUpdatePassword(newPassword);
  setIsPasswordFilled(newPassword.length > 0);

  // Example validation criteria (adjust as per your requirements)
  const isMinLengthValid = newPassword.length >= 8;
  const hasCapitalLetter = /[A-Z]/.test(newPassword);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

  setValidity({
    minLength: isMinLengthValid,
    capital: hasCapitalLetter,
    specialChar: hasSpecialChar,
    // Update other validity checks here
  });
};

const handlePasswordChange1 = (e) => {
  const newPassword = e.target.value;
  setPassword(newPassword);
  setIsPasswordFilled1(newPassword.length > 0);

  // Example validation criteria (adjust as per your requirements)
  const isMinLengthValid = newPassword.length >= 8;
  const hasCapitalLetter = /[A-Z]/.test(newPassword);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

  setValidity1({
    minLength: isMinLengthValid,
    capital: hasCapitalLetter,
    specialChar: hasSpecialChar,
    // Update other validity checks here
  });
};


const handleSearch = (event) => {
  const inputValue = event.target.value;
  setSearchInput(inputValue); 
  setCurrentPage(1); // Reset to the first page for new search
  //}
};


const handleDeleteClick = (item) => {
  setModalData(item); // Set the clicked item data in modalData
  setDeleteName(item.user_name);
  setDeleteId(item.user_id);
};


    return (
        <>
            <Sidebar />
            <div id='header'>
                <div className='logo'>
                    <div className='logo_icon'>
                        <img src={Logo} />
                    </div>
                    <div className='brand_name'>Users and Roles</div> </div>

                <div className='header_table_filter' style={{ width: 'auto' }}>
                    <div className='searchfield searchfield_tab'>
                        <button className='btn_search'><i className='icon-search2'></i></button>
                        <input type='search' className='filter_searchfield' placeholder='Search' value={searchInput}  onChange={handleSearch}  name="search"  autoComplete="off" />
                    </div>
                </div>
            </div>
            <div className="container-fluid content pb-5">
                <div className='tabs_flex_row'>
                    <Tabs activeKey={activeKey} onSelect={setActiveKey} className='TabMenu'>
                        <Tabs.Tab eventKey="Users" title="Users" />
                        {/* <Tabs.Tab eventKey="Roles" title="Roles and Permissions" /> */}
                        {/* <Tabs.Tab eventKey="APIPermissions" title="API Permissions" /> */}
                    </Tabs>

                </div>

                <div className="tab_content_main">
                    {activeKey === 'Users' && (
                        <div className='tab_content_inner'>
                            <ul className='tab_btns_group'>
                                <li><a href='#' className='btn_invite_analyst' data-bs-toggle="modal" data-bs-target="#AddNewAnalystModal"><i className='icon-plus'></i>Add Analysts</a></li>
                            </ul>
                            <div className='row'>
                            {usersData.length > 0 ? (
  usersData.map((item, index) => (
                                <div className='col-sm-6 col-lg-4'>
                                    <div className='roles_col' data-bs-toggle="modal" data-bs-target="#UserSettingsModal" onClick={() => handleItemClick(item)}>
                                        <div className='img_roles'>
                                            <img src={item.profile_img} />
                                        </div>

                                        <div className='text_roles'>
                                          <div className='roleTitleRow'>
                                          <h5>{item.user_name}</h5>
                                          <button className='btn_userRoleDelete' data-bs-toggle="modal" data-bs-target="#ModalDelete" onClick={() => handleDeleteClick(item)} >
                                            <i className='icon-delete'></i>
                                          </button>
                                         </div>
                                            <h6>{item.role_name}</h6>
                                            <p><span>Email:</span> {item.email}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                          <div>
                              No data available
                           </div>
                        )}
                            </div>

                            {usersData && usersData.length > 0 && (
              <nav aria-label="..." className="clsPagination">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <a className="page-link" onClick={() => handlePageChange(currentPage - 1)} style={{ cursor: "pointer" }} > <i className="icon-prev"></i> </a>
                  </li>
                  {[...Array(endPage - startPage + 1).keys()].map((page) => (
                    <li
                      key={startPage + page}
                      className={`page-item ${
                        currentPage === startPage + page ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() => handlePageChange(startPage + page)}
                        style={{ cursor: "pointer" }}
                      >
                        {startPage + page}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icon-next"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            )}
                        </div>
                    )}
                    {/* {activeKey === 'Roles' && (

                      
                    )}
                     */}
                </div>
            </div>           
        
        {/* MODALS */}
        <div class="modal fade user_modals" id="UserSettingsModal" tabindex="-1" aria-labelledby="DuplicateFormModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                {renderMessage()}
                    <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">User Settings</h1>
                    </div>
                    <div class="modal-body">
                    {modalData ? (
                        <>
                        <div className='user_profile_row'>     
                            <div className='user_profile_pic'>     
                                <p>Profile Picture</p>
                                <div className={`avatar-upload ${isImageUploaded ? 'image-uploaded' : ''}`}>  
                                    <div className='default_preview_avtar'>
                                        <img src={IconImageThumb} />
                                        <p>Upload your <br />photo</p>
                                    </div>                         
                                    <div className="avatar-preview">
                                        <div 
                                            id="imagePreview" 
                                            style={{ backgroundImage: `url(${updateImage})` }}>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='user_profile_details'>                                 
                                <div className='title_flex_sp'>
                                    <h5>{modalData.firstname + ' ' + modalData.lastname}</h5>
                                    {/* <div className='date_time_status'><span className='red_circle_status'></span>12 Oct 2023, <span className='status_time'>5:09 AM</span></div> */}
                                </div>
                                <h6>{modalData.role_name}</h6>   
                                <p><span>Email:</span> {modalData.email}</p> 
                                <div className='btn__group'>
                                    <div className="avatar-edit">
                                    <input 
                                        type='file' 
                                        id="imageUpload" 
                                        accept=".png, .jpg, .jpeg" 
                                        onChange={handleImageChange} 
                                    />
                                    <label htmlFor="imageUpload">Upload new Profile</label>
                                </div>
                                {isImageUploaded && (
                                    <button type='button' className='btn__upload__remove' onClick={handleRemoveClick}>Remove</button>
                                )}
                                </div>
                                
                            </div>
                        </div>

                        <div className='profile_form_row'>
                            <h5>BASIC INFO</h5>
                            <div className='row'>
                                <div className='col-sm-6 mt-4'>
                                    <label>First Name</label>
                                    <input type='text'  
                                    value={updateFirstname} 
                                    onChange={(e) => setUpdateFirstname(e.target.value)}
                                    className={`form-control ${updateFirstname.trim() !== '' ? 'form_control_no_border' : ''}`}
                                    placeholder='Enter First Name' />
                                </div>

                                <div className='col-sm-6 mt-4'>
                                    <label>Last Name</label>
                                    <input type='text'  
                                    value={updateLastname} 
                                    onChange={(e) => setUpdateLastname(e.target.value)}
                                    className={`form-control ${updateLastname.trim() !== '' ? 'form_control_no_border' : ''}`}
                                    placeholder='Enter Last Name' />
                                </div>

                                <div className='col-sm-6 mt-4'>
                                    <label>Role</label>
                                    <SelectPicker
                                        data={roleData}
                                        searchable={false}
                                        className='custom_select_drop'
                                        style={{}}
                                        placeholder="Select Role"
                                        onChange={handleRoleChange}
                                        value={role}

                                        />
                                </div>
                                
                                <div className='col-sm-6 mt-4'>
                                    <label>Email</label>
                                    <input type='text'  
                                     value={updateEmail} 
                                     onChange={(e) => setUpdateEmail(e.target.value)}
                                    className={`form-control ${updateEmail.trim() !== '' ? 'form_control_no_border' : ''}`}
                                    placeholder='Enter Email Address' />
                                </div>
                            </div>

                            <h5 className='mt-4'>Change Password</h5>

                            <div className='row'>
                                <div className='col-sm-6 mt-4'>
                                    <label htmlFor='current-password'>Password</label>
                                    <div className='text_field'>
                                    <input
                                        type={showCurrentPassword ? 'text' : 'password'}
                                        id='current-password'
                                        value={updatePassword} 
                                        //onChange={(e) => setUpdatePassword(e.target.value)}
                                        onChange={handlePasswordChange}
                                        className={`form-control ${updatePassword.trim() !== '' ? 'form_control_no_border' : ''}`}
                                        placeholder='Enter Password'
                                        autoComplete='new-password'
                                    />

                                    <div className='icon_field' onClick={toggleCurrentPasswordVisibility}>
                                        {showCurrentPassword ? <PasswordHide /> : <PasswordShow />}
                                    </div>
                                    </div>
                                </div>

                                <div className='col-sm-6 mt-4'>
                                    <label htmlFor='new-password'>Confirm Password</label>
                                    <div className='text_field'>
                                    <input
                                        type={showNewPassword ? 'text' : 'password'}
                                        id='new-password'
                                        value={updateConfirmPassword} 
                                        onChange={(e) => setUpdateConfirmPassword(e.target.value)}
                                        className={`form-control ${updateConfirmPassword.trim() !== '' ? 'form_control_no_border' : ''}`}
                                        placeholder='Enter Confirm Password'
                                        autoComplete='new-password'
                                    />
                                    <div className='icon_field' onClick={toggleNewPasswordVisibility}>
                                        {showNewPassword ? <PasswordHide /> : <PasswordShow />}
                                    </div>
                                    </div>
                                </div>
                                </div>
                        </div>          
                        </>
                    ) : (
                        <p>No data available</p>
                    )}

                    </div>

                    <div className='password_error_msg' style={{paddingLeft:20}}>
                  {!isPasswordFilled && (
                    <ul className='message_default'>
                      <li>Must be at least 8 characters long</li>
                      <li>Include both uppercase and lowercase letters</li>
                      <li>Use special characters and numbers</li>
                    </ul>
                  )}

                  {isPasswordFilled && (
                    <ul className='message_after_type'>
                      <li className={validity.minLength ? 'valid' : 'invalid'}>Must be at least 8 characters long</li>
                      <li className={validity.capital ? 'valid' : 'invalid'}>Include both uppercase and lowercase letters</li>
                      <li className={validity.specialChar ? 'valid' : 'invalid'}>Use special characters and numbers</li>
                    </ul>
                  )}

                  {/* Example icons based on validity */}
                  {isPasswordFilled && Object.values(validity).every(v => v) && (
                    <IconValid />
                  )}
                  {isPasswordFilled && !Object.values(validity).every(v => v) && (
                    <IconInvalid />
                  )}
                </div>

         

                    <div class="btn__group btn_group_modal mt-4" style={{borderRadius: '0 0 16 16', background: '#FFF9EF', padding:12,}}>
                        <button type="button" className="btn_cancel" data-bs-dismiss="modal" id={`Cancel_Update`}>Cancel</button>
                        <Button type="button" className="btn_save_changes" onClick={handleUpdate} loading={updateButtonLoader}>Save Changes</Button>
                    </div>   


                    
                    </div>
                </div>
            </div>


            <div class="modal fade user_modals" id="AddNewAnalystModal" tabindex="-1" aria-labelledby="AddNewAnalystModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
               
                    <div class="modal-content">
                    
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Add New Analyst</h1>
                    </div>
                    <div class="modal-body">
                    {renderMessage()}
                        <div className='user_profile_row'>     
                            <div className='user_profile_pic'>     
                                <p>Profile Picture</p>
                                <div className={`avatar-upload2 ${isImageUploaded1 ? 'image-uploaded' : ''}`}>                                                            
                                    <div className="avatar-preview">  
                                        <div className='default_preview_avtar'>
                                            <img src={IconImageThumb} />
                                            <p>Upload your <br />photo</p>
                                        </div>     
                                        <div 
                                            id="imagePreview2" 
                                            style={{position:'relative', backgroundImage: `url(${image})` }}>
                                           
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className='user_profile_details'>                                 
                                {/* <div className='title_flex_sp'>
                                    <h5>Full Name</h5>                                    
                                </div>
                                <h6>Role of User</h6>   
                                <p><span>Email:</span> firstname.lastname@musaffa.com</p>  */}
                                <div className='btn__group'>
                                    <div className="avatar-edit">
                                    <input 
                                        type='file' 
                                        id="imageUpload2" 
                                        accept=".png, .jpg, .jpeg" 
                                        onChange={handleImageChange1} 
                                    />
                                    <label htmlFor="imageUpload2">Upload New Profile</label>
                                </div>
                                
                                    <button type='button' className='btn__upload__remove' onClick={handleRemoveClick1}>Remove</button>
                                   
                                </div>
                                
                            </div>
                        </div>

                        <div className='profile_form_row'>
                            <h5>BASIC INFO</h5>
                            <div className='row'>
                                <div className='col-sm-6 mt-4'>
                                    <label>First Name</label>
                                    <input type='text'  
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    className={`form-control ${firstname.trim() !== '' ? 'form_control_no_border' : ''}`}
                                    placeholder='Enter First Name' />
                                </div>

                                <div className='col-sm-6 mt-4'>
                                    <label>Last Name</label>
                                    <input type='text'  
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    className={`form-control ${lastname.trim() !== '' ? 'form_control_no_border' : ''}`}
                                    placeholder='Enter Last Name' />
                                </div>

                                <div className='col-sm-6 mt-4'>
                                    <label>Role</label>
                                    <SelectPicker
                                        data={roleData}
                                        searchable={false}
                                        className='custom_select_drop'
                                        style={{}}
                                        placeholder="Select Role"
                                        onChange={handleRoleChange}
                                        value={role}

                                        />
                                </div>

                                <div className='col-sm-6 mt-4'>
                                    <label>Email</label>
                                    <input type='text'  
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={`form-control ${email.trim() !== '' ? 'form_control_no_border' : ''}`}
                                    placeholder='Enter Email Address' />
                                </div>
                            </div>

                            {/* <h5 className='mt-4'>Add Password</h5>

                            <div className='row'>
                                <div className='col-sm-6 mt-4'>
                                    <label htmlFor='current-password'>New Password</label>
                                    <div className='text_field'>
                                    <input 
                                    value={password}
                                    onChange={handlePasswordChange1}
                                    className={`form-control ${password.trim() !== '' ? 'form_control_no_border' : ''}`}
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    
                                    id='current-password'
                                    placeholder='Enter Current Password'
                                    />
                                    <div className='icon_field' onClick={toggleCurrentPasswordVisibility}>
                                        {showCurrentPassword ? <PasswordHide /> : <PasswordShow />}
                                    </div>
                                    </div>
                                </div>

                                <div className='col-sm-6 mt-4'>
                                    <label htmlFor='new-password'>Confirm Password</label>
                                    <div className='text_field'>
                                    <input
                                        type={showNewPassword ? 'text' : 'password'}
                                        id='new-password'
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className={`form-control ${confirmPassword.trim() !== '' ? 'form_control_no_border' : ''}`}
                                        placeholder='Enter New Password'
                                    />
                                    <div className='icon_field' onClick={toggleNewPasswordVisibility}>
                                        {showNewPassword ? <PasswordHide /> : <PasswordShow />}
                                    </div>
                                    </div>
                                </div>
                                </div> */}
                        </div>
                                                                         
                    </div>
                    
                    {/* <div className='password_error_msg' style={{paddingLeft:20}}>
                  {!isPasswordFilled1 && (
                    <ul className='message_default'>
                      <li>Must be at least 8 characters long</li>
                      <li>Include both uppercase and lowercase letters</li>
                      <li>Use special characters and numbers</li>
                    </ul>
                  )}

                  {isPasswordFilled1 && (
                    <ul className='message_after_type'>
                      <li className={validity1.minLength ? 'valid' : 'invalid'}>Must be at least 8 characters long</li>
                      <li className={validity1.capital ? 'valid' : 'invalid'}>Include both uppercase and lowercase letters</li>
                      <li className={validity1.specialChar ? 'valid' : 'invalid'}>Use special characters and numbers</li>
                    </ul>
                  )}

                  
                  {isPasswordFilled1 && Object.values(validity1).every(v => v) && (
                    <IconValid />
                  )}
                  {isPasswordFilled1 && !Object.values(validity1).every(v => v) && (
                    <IconInvalid />
                  )}
                </div> */}


                    <div class="btn__group btn_group_modal mt-4" style={{borderRadius: '0 0 16 16', background: '#FFF9EF', padding:12,}}>
                        <button type="button" className="btn_cancel" data-bs-dismiss="modal" id={`Cancel_Add`}>Cancel</button>
                        <Button type="button" className="btn_save_changes" onClick={handleAdd} loading={addButtonLoader}>Save Changes</Button>
                    </div>   
                    
                    </div>
                </div>
            </div>


 {/* Modal */}
 <div className="modal fade duplicate_form_modal" id="ModalDelete" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
         
          <div className="modal-body">
            <div className='duplicate_form_content'>
              <h5 style={{fontSize:20, color: "#141B3C",fontWeight: 600, lineHeight:1.3, textAlign:'center'}}>
                Are you sure you want to <br />
              delete {deleteName}?</h5>
              <div className='btn__group mt-3'>
                                      <a href="#" className='btn__cancel' data-bs-dismiss="modal" id={`Delete_Update`} >Cancel</a>
                                      <a href="#" className='btn__assign' onClick={handleDelete}>Delete</a>
                                      
                                    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Modal */}



        </>
    );
}

export default UsersandRoles;
