import React, { useState, useEffect, useCallback } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Popover, Whisper, Button, Message, Tooltip } from 'rsuite';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import debounce from 'lodash.debounce';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Margin } from '@mui/icons-material';
import { Center } from '@chakra-ui/react';

function DeletedReports() {
  const [tabName, setTabName] = useState('Deleted Reports');
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  useEffect(() => {
    AOS.init();
  }, []);
  

  const userid = localStorage.getItem("novauserid");
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [loadingRowId, setLoadingRowId] = useState(null);


  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(true); // State to manage loading state

  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info
  const [searchInput, setSearchInput] = useState("");
  
  
   
   const fetchMissingCompanies = async () => {
    setLoading(true);
   
    try {
        const apiUrl = `${apiEndPoint}/getDeletedTasks`;
        const requestData = {
          data: {
            user_id: userid,
            limit: "20",
            page: currentPage.toString(),
            q:searchInput,
          }
        };
        console.log(JSON.stringify(requestData));
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        });
  
        const jsonData = await response.json();
        const { status, message, count, data } = jsonData.data;
        if (status == "success") {
          setLoading(false);
          setReportCount(count);
          console.log('RES :'+ searchInput + ' : '+ JSON.stringify(data));
          setReportData(data);
        } else if(status == "error"){
          setLoading(false);
          setReportCount(count);
          setReportData(data);
          console.error("Error: ", message);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
      setLoading(false);
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
        fetchMissingCompanies();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);

  useEffect(() => {
    fetchMissingCompanies();
  }, [currentPage]);


  const totalPages = Math.ceil(reportCount / 20);
  const endPage = Math.min(startPage + 4, totalPages);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (page >= startPage + 5) {
      setStartPage(page - 4);
    } else if (page < startPage) {
      setStartPage(page);
    }
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
        </li>
      );
    }
  
    return (
      <nav aria-label="..." id='pagination_row'>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
              <i className='fa fa-angle-double-left'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
              <i className='icon-prev'></i>
            </a>
          </li>
          {pages}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
              <i className='icon-next'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
              <i className='fa fa-angle-double-right'></i>
            </a>
          </li>
        </ul>
      </nav>
    );
  };


 


  
  const handleClearFilters = () => {
    setCurrentPage(1);
    setSearchInput("");

  };


const hideMessage = () => {
  setMessageType(null);
};


// Popup For Progress : Delete Report
const [openDeletePopover, setOpenDeletePopover] = useState(null);
const handleDeletePopoverOpen = (id) => {
  setOpenDeletePopover(openDeletePopover == id ? null : id);
};


const handleRecoverConfirm = (recoverId) => {
 recoverReport(recoverId);
};

const recoverReport = async (recoverId) => {
  const url = `${apiEndPoint}/recoverTask`;

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ data: { user_id: userid, id: recoverId } }),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
    const data = responseData.data; // Flatten the response
    if(data.status === 'success')
      {
        setErrorMessage(data.message);
        setMessageType('success');
        //setDeleteId(null);// Reset the deleteId after deletion
        fetchMissingCompanies();
        setOpenDeletePopover(null);
      }
      else if(data.status === 'error'){
        setErrorMessage(data.message);
        setMessageType('error');
        setOpenDeletePopover(null);
        
        return false;
      }
  } catch (error) {
    setOpenDeletePopover(null);
    setErrorMessage(error.message);
    setMessageType('error');
    return false;
  }
};

const renderMessage = () => {
  if (messageType) {
    setTimeout(hideMessage, 5000);
  };

  switch (messageType) {
    case 'success':
      return (
        <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10,zIndex:99}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_success'>
              <div className='icon_error_msg_inner Icon_success_inner'>
                <img src={IconCheckError} alt="Success Icon" />
                <div className="progress-circle progress_circle_success"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_success_text'>
              <h5>Success</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
    case 'error':
      return (
        <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10,zIndex:99}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_error'>
              <div className='icon_error_msg_inner Icon_error_inner'>
                <img src={IconError} alt="Error Icon" />
                <div className="progress-circle progress_circle_error"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_error_text'>
              <h5>Error</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      case 'info':
      return (
        <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10,zIndex:99}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_info'>
              <div className='icon_error_msg_inner Icon_info_inner'>
                <img src={IconInfo} alt="Info Icon" />
                <div className="progress-circle progress_circle_info"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_info_text'>
              <h5>Info</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      default:
        return null;
  }
};

const handleSearch = (event) => {
  setSearchInput(event.target.value);
  setCurrentPage(1); // Reset to the first page for new search
};



  return (
    <>
      <Sidebar />
      <div id='header'>
      {renderMessage()}
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Deleted Reports ({reportCount})</div>
        </div>

        <div className='header_table_filter'>
        <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>

            <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
      <div className='company_table_list' data-aos="fade-up" data-aos-duration="600">
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th style={{textAlign:'center'}}>Symbol</th>
                  <th style={{textAlign:'centerx'}}>Quarter</th>
                  <th style={{textAlign:'centerx'}}>Deleted By</th>
                  <th style={{textAlign:'center'}}>Action</th>
                </tr>
              </thead>
              <tbody>
              {reportData.length > 0 ? (
  reportData.map((request, index) => (
    <tr key={index}>
    <td style={{textAlign:'center'}}><span style={{margin:'auto'}} className='company_lable2'>{request.company_symbol}</span></td>
    <td style={{textAlign:'centerz'}}>{`Q${request.quarter}-${request.year}`}</td>
    <td style={{textAlign:'centerz'}}>
                      <div className='company_name_td' >
                        {request.deleted_msg}
                      </div>
                    </td>
                    <td style={{textAlign:'center'}}>
                    <Whisper
          placement="left"
          trigger="click"
          open={openDeletePopover == request.id}
          //onOpen={() => handlePopoverOpen(item.id)}
          onClose={() => setOpenDeletePopover(null)}
          speaker={
            <Popover className="" preventOverflow>
              <div className="tooltip_form_inner text-center">
                                    <div style={{fontSize:20, color: "#141B3C",fontWeight: 600, lineHeight:1.3}}>
                                        Are you sure you want to <br />
                                        recover this report ?
                                      </div>
                                      {/* <p style={{color: '#696969',fontSize: 14,fontWeight: 500, marginTop: 15,}}>
                                        The report of {request.name} will be deleted from your task manager but will remain in the database
                                      </p> */}

                                    <div className='btn__group mt-5'>
                                      <a href="#" className='btn__cancel' onClick={() => setOpenDeletePopover(null)}>Cancel</a>
                                      <a href="#" className='btn__assign'  onClick={() => handleRecoverConfirm(request.id)}>Recover</a>
                                      
                                    </div>
                                </div>
            </Popover>
          }
        >
                    <button style={{margin:'auto'}} className="btn_completed" onClick={() => handleDeletePopoverOpen(request.id)}> Recover </button>
       </Whisper>
                    </td>
  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan="1" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

          {totalPages > 1 && renderPagination()}


        </>    
)}
        </div>
      </div>
    </>
  );
}
export default DeletedReports;
